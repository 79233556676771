.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 322px;
  height: 80px;
  object-fit: cover;
}
.home-burger-menu {
  fill: #105749;
  width: 24px;
  height: 24px;
}
.home-max-width1 {
  align-items: center;
}
.home-content {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-subtitle {
  color: var(--dl-color-scheme-orange100);
  margin-bottom: var(--dl-space-space-unit);
}
.home-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text {
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
}
.home-text1 {
  color: var(--dl-color-scheme-lightgreen);
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
}
.home-text2 {
  color: var(--dl-color-scheme-orange80);
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
}
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
  text-transform: uppercase;
}
.home-image1 {
  position: relative;
  padding-bottom: var(--dl-space-space-eightunits);
}
.home-hero-image {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.home-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.home-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
@media(max-width: 991px) {
  .home-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image {
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-icon {
    fill: var(--dl-color-scheme-green100);
  }
  .home-max-width1 {
    flex-direction: column-reverse;
  }
  .home-content {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image {
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 0px;
  }
}
